import { ContainerPageInside } from '../../../styles/ContainerPage';
import { Outlet } from 'react-router-dom';

export default props => {
    return (
        <ContainerPageInside>
            <Outlet />
        </ContainerPageInside>
    );
};
