import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

const AppContext = createContext({});

export const AppProvider = props => {
    const [loading, setLoading] = useState(false);
    const [isOffline, setOnline] = useState(false);
    const [globalPesquisa, setGlobalPesquisa] = useLocalStorage('@globalPesquisa', {});
    const [openMenu, setOpenMenu] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState({});
    const [resultFilter, setFilterResult] = useState([]);
    const [baseLocation, setBaseLocation] = useState(false);
    const [userId, setUserId] = useState();

    const network = e => {
        const { type, returnValue } = e;
        if (type === 'offline') {
            setOnline(true);
        }

        if (type === 'online') {
            setOnline(false);
        }
    };

    useEffect(() => {
        const online = window.addEventListener('online', network);
        const offline = window.addEventListener('offline', network);

        return () => {
            window.removeEventListener('online', network);
            window.removeEventListener('offline', network);
        };
    });

    return (
        <AppContext.Provider
            value={{
                loading,
                setLoading,
                isOffline,
                openMenu,
                setOpenMenu,
                openFilter,
                setOpenFilter,
                filter,
                setFilter,
                resultFilter,
                setFilterResult,
                baseLocation,
                setBaseLocation,
                userId, 
                setUserId
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) throw 'useApp context inside corret provider';

    return context;
};
