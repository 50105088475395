import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth } from '../modules/Auth/context/AuthContext';

const useHandleErrors = errors => {
    const { LogOut } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (errors) {
            if (!Boolean(errors?.response)) {
                enqueueSnackbar(errors.message);
                return;
            }

            switch (errors.response.status) {
                case 401:
                    sessionStorage.removeItem('token');
                    LogOut();
                    break;
                case 400:
                    if (errors.response) {
                        if (Array.isArray(errors.response.data.message)) {
                            errors.response.data.message.forEach(item => {
                                enqueueSnackbar(item.message);
                            });
                        } else {
                            enqueueSnackbar(errors.response.data.message);
                        }
                    } else {
                        enqueueSnackbar(errors.message);
                    }
                    break;
                case 600:
                    if (errors.response) {
                        if (Array.isArray(errors.response.data.message)) {
                            errors.response.data.message.forEach(item => {
                                enqueueSnackbar(item.message);
                            });
                        } else {
                            enqueueSnackbar(errors.response.data.message);
                        }
                    } else {
                        enqueueSnackbar(errors.message);
                    }
                    break;
                case 200:
                    enqueueSnackbar(errors.response.data.message);
                    break;

                case 0:
                    enqueueSnackbar('Servidor Instável. Tente mais tarde.');
                    break;
                default:
                    if (errors.response) {
                         
                        if (errors.response.data.message) {
                            enqueueSnackbar(errors.response.data.message);
                        } else {
                            enqueueSnackbar(errors.response.message);
                        }
                    } else {
                         
                        enqueueSnackbar(errors.message);
                    }
                    break;
            }
        }
    }, [errors]);
};

export default useHandleErrors;
