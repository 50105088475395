import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useAuth } from '../../context/AuthContext';
import server from '../../../../global/server';
import useHandleErrors from '../../../../hooks/useHandleErrors';
import Typography from '@mui/material/Typography';
import { useApp } from '../../../../context/AppContext';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { notify } from 'react-notify-toast';

export default props => {
    const { verifySession, setUser } = useAuth();
    const { isOffline } = useApp();
    const [hasError, setError] = useState();
    useHandleErrors(hasError);

    const navigation = useNavigate()

    const RegisterSchema = Yup.object().shape({
        email: Yup.string().email().required('Campo obrigatório')
    });

    const { enqueueSnackbar } = useSnackbar();
    const { values, errors, touched, handleChange, handleBlur,handleReset, handleSubmit, isSubmitting } = useFormik({
        initialValues: { email: '' },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const { data } = await server.post('/auth/recovery',values);
                notify.show('Enviamos uma nova senha para o seu e-mail')
                handleReset()
                navigation('/profile')
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setError(error);
            }
        },
    });

    return (
        <PanelAuth>
            <div>
                {/* <Logo>
                    <img width={250} src={logo}sx={{ marginBottom: 20 }} />
                </Logo> */}
                <BoxAuth>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" gap={2}>
                            <Grid>
                                <Title>
                                    <Typography variant="h6">Recuperar senha</Typography>
                                </Title>
                            </Grid>
                             
                            <Grid>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="E-mail"
                                    type="text"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={errors.email}
                                    disabled={isOffline}
                                    helperText={errors.email}
                                />
                            </Grid>
                             
                            <Grid>
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    loading={isSubmitting}
                                    variant="contained"
                                    disabled={errors.email || isSubmitting || isOffline}
                                >
                                    Recuperar
                                </LoadingButton>
                            </Grid>
                            <Grid>
                                <LoadingButton onClick={() => navigation('/profile')} fullWidth variant="text" disabled={isSubmitting}>
                                    Voltar
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </BoxAuth>
            </div>
        </PanelAuth>
    );
};

const PanelAuth = styled.div`
    width: 100%;
    height: 100%;
    display: flexbox;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #eee;
`;

const BoxAuth = styled(Grid)`
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 2rem;
    width: 450px;
    background: white;
`;

const Title = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const BoxAuthContainer = styled.div`
    padding: 1px;
`;
