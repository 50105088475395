import styled from 'styled-components';
import { useApp } from '../../../context/AppContext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SelectCountry from '../../../components/SelectCountry';
import SelectState from '../../../components/SelectState';
import SelectCity from '../../../components/SelectCity';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import server from '../../../global/server';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SelectLanguages from '../../../components/SelectLanguages';
import SelectEducation from '../../../components/SelectEducation';
import SelectOccupation from '../../../components/SelectOccupation';
import SelectCertification from '../../../components/SelectCertification';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import InputMask from 'react-input-mask';
import { notify } from 'react-notify-toast';

export default props => {
    const { openFilter, setOpenFilter, filter, setFilter, setFilterResult, setBaseLocation } = useApp();

    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { values, errors, touched, isSubmitting, setValues, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit } = useFormik({
        initialValues: {
            zipcode: '',
            country: '',
            state: '',
            city: '',
            nationality: '',
            certifications: [],
            occupationArea: [],
            education: [],
            professions: [],
            languages: [],
            keywords: '',
            distance: 10,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                setFilter(values);
                const response = await server.get(`/search`, {
                    params: values,
                });

                if (response.data.data.resultFilter.length === 0) {
                    notify.show('Nenhum resultado para o filtro');
                    setFilterResult([]);
                    setBaseLocation(response.data.data.baseLocation);
                    setOpenFilter(false);
                } else {
                    notify.show('Resultado encontrado');
                    setFilterResult(response.data.data.resultFilter);
                    setBaseLocation(response.data.data.baseLocation);
                    setOpenFilter(false);
                }

                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (Boolean(Object.values(filter).length)) {
            setValues(filter);
        }
    }, [filter]);

    return (
        <Drawer anchor="left" open={openFilter} onClose={() => setOpenFilter(false)}>
            <Box sx={{ maxWidth: 700, paddingX: 3 }} role="presentation">
                <Typography variant="h5" component="h3" p={2}>
                    Filtros de pesquisa
                </Typography>
                <Divider />
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={8} md={9}>
                        <InputMask
                            mask="99999999"
                            name="zipcode"
                            onChange={handleChange}
                            error={errors.zipcode}
                            helperText={errors.zipcode}
                            value={values.zipcode}
                            label="CEP"
                        >
                            {inputProps => <TextField {...inputProps} fullWidth disableUnderline style={{ WebkitAppearance: 'none' }} />}
                        </InputMask>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TextField
                            fullWidth
                            disabled={!Boolean(values.zipcode)}
                            name="distance"
                            onChange={handleChange}
                            error={errors.distance}
                            helperText={errors.distance}
                            value={values.distance}
                            label="Distância"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                            }}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectCountry
                            name="country"
                            disabled={Boolean(values.zipcode)}
                            value={values.country}
                            error={errors.country}
                            helperText={errors.country}
                            onChange={e => setFieldValue('country', e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectState
                            name="state"
                            disabled={Boolean(values.zipcode)}
                            value={values.state}
                            country={values.country}
                            error={errors.state}
                            helperText={errors.state}
                            onChange={e => setFieldValue('state', e)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectCity
                            name="city"
                            disabled={Boolean(values.zipcode)}
                            value={values.city}
                            country={values.country}
                            state={values.state}
                            error={errors.city}
                            helperText={errors.city}
                            onChange={e => setFieldValue('city', e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectCertification
                            fullWidth
                            filter
                            name="certification"
                            label="Certificações"
                            error={errors.certifications}
                            helperText={errors.certifications}
                            value={values.certifications}
                            onChange={e => setFieldValue('certifications', e)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SelectEducation
                            fullWidth
                            filter
                            name="education"
                            label="Formação"
                            error={errors.education}
                            helperText={errors.education}
                            value={values.education}
                            onChange={e => setFieldValue('education', e)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <SelectOccupation
                            fullWidth
                            filter
                            name="occupationArea"
                            label="Disciplinas"
                            error={errors.occupationArea}
                            value={values.occupationArea}
                            onChange={e => setFieldValue('occupationArea', e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectLanguages
                            fullWidth
                            filter
                            name="languages"
                            label="Idiomas"
                            error={errors.languages}
                            value={values.languages}
                            onChange={e => setFieldValue('languages', e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="keywords"
                            onChange={handleChange}
                            error={errors.keywords}
                            value={values.keywords}
                            label="Palavra-chave"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton variant="contained" onClick={() => handleSubmit()} loading={isSubmitting} fullWidth>
                            Aplicar filtro e buscar
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            onClick={() => {
                                handleReset();
                                //setOpenFilter(false);
                            }}
                        >
                            Limpar filtro
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={() => setOpenFilter(false)} fullWidth>
                            Fechar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
};

const ContainerFilter = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
`;
