import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFetch } from '../hooks/useFetch';
import { FormHelperText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default ({ value, filter, label, error, helperText, onChange, ...props }) => {
    
    // const data = [
    //     { id: 1, language: 'english_basic', name: 'Ingles Basico' },
    //     { id: 2, language: 'english_middle', name: 'Ingles Intermediário' },
    //     { id: 3, language: 'english_full', name: 'Ingles Avançado' },
    //     { id: 4, language: 'portuguese_basic', name: 'Português Básico' },
    //     { id: 5, language: 'portuguese_middle', name: 'Português Intermediário' },
    //     { id: 6, language: 'portuguese_full', name: 'Português Avançado' },
    //     { id: 7, language: 'spanish_basic', name: 'Espanhol Basico' },
    //     { id: 8, language: 'spanish_middle', name: 'Espanhol Intermediário' },
    //     { id: 9, language: 'spanish_full', name: 'Espanhol Avançado' },
    // ];

    const { data } = useFetch('/settings/language', {
        params: { order: 'ASC' },
    });

    if (filter) {
        return (
            <Autocomplete
                {...props}
                multiple
                getOptionLabel={render => render.name}
                options={data || []}
                value={value}
                loading={!Boolean(data)}
                onChange={(e, value, reason, details) => onChange(value)}
                filterSelectedOptions
                name="LanguagesUsers"
                sx={{ width: '100%' }}
                renderInput={params => <TextField {...params} error={Boolean(error)} helperText={helperText} label={label} />}
            />
        );
    }

    return (
        <>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={data || []}
                getOptionLabel={data => data.name}
                value={data ? data.filter(item => value.map(({ language_id }) => language_id).includes(item.id) && item) : []}
                filterSelectedOptions
                name="LanguagesUsers"
                onChange={(e, value, reason, details) =>
                    onChange({ name: 'LanguagesUsers', value: value.map(({ id, ...item }) => ({ ...item, language_id: id})) }, reason)
                }
                renderInput={params => <TextField {...params} error={Boolean(error)} label="Idiomas" placeholder="Idiomas" />}
            />
            <FormHelperText error={error}>{error}</FormHelperText>
        </>
    );
};
