import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useApp } from '../../../context/AppContext';
import RoomIcon from '@mui/icons-material/Room';
import { GoogleMap, useJsApiLoader, Marker, Circle, InfoWindow } from '@react-google-maps/api';
import { Button, Typography } from '@mui/material';

const MarkerAndInfoWindow = ({ item }) => {
    const { setUserId, userId } = useApp();

    const { id, latitude, longitude, distance_for, User } = item;

    const markerRef = useRef();

    const onload = marker => {
        markerRef.current = marker;
    };

    if (!Boolean(latitude) || !Boolean(longitude)) return null;
    return (
        <>
            <Marker
                icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 12,
                    fillColor: 'red',
                    fillOpacity: 0.3,
                    strokeWeight: 2,
                    strokeColor: 'red',
                }}
                visible={true}
                onLoad={onload}
                onClick={() => setUserId(id)}
                position={{ lat: latitude, lng: longitude }}
            />
            <InfoWindow anchor={markerRef.current} position={{ lat: latitude, lng: longitude }}>
                <div>
                    <Typography variant="subtitle2">{User.name} </Typography>
                    {Boolean(item.distance_for) && <Typography variant="subtitle2">{distance_for.toFixed(2)}Km</Typography>}
                    <Button onClick={() => setUserId(id)}>Ver perfil</Button>
                </div>
            </InfoWindow>
        </>
    );
};
export default props => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEKEY,
    });

    const [map, setMap] = React.useState(null);
    const { filter, resultFilter, baseLocation, setUserId, userId } = useApp();

    const [center, setCenter] = useState({
        lat: -22.9935562,
        lng: -43.4023995,
    });

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    useEffect(() => {
        if (Boolean(resultFilter.length) && Boolean(map)) {
            //setCenter(baseLocation);
            const bounds = new window.google.maps.LatLngBounds();
            resultFilter.forEach(({ latitude, longitude }) => (Boolean(latitude) && Boolean(longitude)) && bounds.extend({ lat: latitude, lng: longitude }));
            if (baseLocation) bounds.extend(baseLocation);
            map.fitBounds(bounds);
            setMap(map);
        }
    }, [resultFilter, map]);

    const onLoad = useCallback(map => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(map => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18} onLoad={onLoad} onUnmount={onUnmount}>
            <>
                {resultFilter.map(item => (
                    <MarkerAndInfoWindow key={item.id} item={item} />
                ))}
                {baseLocation && (
                    <>
                        <Marker
                            icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 12,
                                fillColor: 'yellow',
                                fillOpacity: 0.7,
                                strokeWeight: 2,
                                strokeColor: 'yellow',
                            }}
                            label="B"
                            title="Localização base"
                            clickable
                            position={baseLocation}
                        />
                        <Circle
                            radius={filter.distance * 1000}
                            center={baseLocation}
                            options={{
                                strokeColor: '#1976d2',
                                strokeOpacity: 0.4,
                                strokeWeight: 1,
                                fillColor: '#1976d2',
                                fillOpacity: 0.3,
                                clickable: false,
                                draggable: false,
                                editable: false,
                                visible: true,
                                radius: filter.distance * 1000,
                                zIndex: 1,
                            }}
                        />
                    </>
                )}
            </>
        </GoogleMap>
    ) : (
        <></>
    );
};
