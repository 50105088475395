import  Button  from '@mui/material/Button';
import styled from 'styled-components';
import { useApp } from '../../../context/AppContext';
import SearchResult from './SearchResult';

export default ()=> {
    const { setOpenFilter, filter  } = useApp();

    if (!Boolean(Object.keys(filter).length ))
        return (
            <>
                <ContainerFilter>
                    <Button variant='contained' onClick={() => setOpenFilter(true)} >
                        Adicionar filtro
                    </Button>
                </ContainerFilter>
            </>
        );

    return <SearchResult  />;
};

const ContainerFilter = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
`;
