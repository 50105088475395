import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import Calendar from '@mui/icons-material/CalendarMonthRounded';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';

import SelectCertification from '../../../components/SelectCertification';
import { Box, CardContent, Chip } from '@mui/material';
import InputFile from '../../../components/InputFile';
import { Stack } from '@mui/system';

const TRANSLATION = {
    pt: {
        english: 'Inglês',
        portuguese: 'Português',
    },
    en: {
        english: 'English',
        portuguese: 'Portuguese',
    },
};
export default ({ id }) => {
    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { data, error, mutate } = useFetch(`professional/${id}/certificate`);
    useHandleErrors(error);
    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const { values, errors, touched, isSubmitting, setFieldError, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit, setValues } =
        useFormik({
            initialValues: {
                certificate_name: '',
                certification_id: '',
                validity: '',
                document: '',
            },
            onSubmit: async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true);
                    await server.post(`professional/${id}/certificate`, values);
                    handleReset();
                    mutate();
                    enqueueSnackbar('Dados inseridos');
                    setSubmitting(false);
                } catch (error) {
                    setSubmitting(false);
                    setErrors(error);
                }
            },
        });

    const remove = async item => {
        try {
            await server.delete(`professional/${id}/certificate/${item.id}`);
            enqueueSnackbar('Item removido');
            mutate();
            setValues({});
        } catch (error) {
            setErrors(error);
        }
    };

    const handleRemove = item => {
        confirm({ title: 'Excluir', description: 'Confirma a exlusão' })
            .then(res => {
                remove(item);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <Box sx={{ marginX: 3, marginY: 4 }}>
            <Grid sx={{ marginBottom: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Adicione as atividades que você já realizou durante a sua carreira.</Typography>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit}>
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} md={6} align="middle">
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Nome do certificador"
                                type="text"
                                name="certificate_name"
                                error={Boolean(errors.certificate_name) || !touched.certificate_name}
                                helperText={errors.certificate_name}
                                value={values.certificate_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} align="middle">
                            <SelectCertification required value={values.certification_id} onChange={e => setFieldValue('certification_id', e)} />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid item xs={12} md={6}>
                                <DesktopDatePicker
                                    label="Vencimento"
                                    required
                                    inputFormat="dd/MM/yyyy"
                                    error={Boolean(errors.validity) || !touched.validity}
                                    helperText={errors.validity}
                                    value={values.validity}
                                    onChange={e => setFieldValue('validity', e)}
                                    name="validity"
                                    renderInput={params => <TextField {...params} fullWidth />}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12} md={6} align="middle">
                            <InputFile
                                variant="outlined"
                                fullWidth
                                onBlur={handleBlur}
                                type="text"
                                name="document"
                                error={Boolean(errors.document) || !touched.document}
                                helperText={errors.document}
                                value={`${values.document ? values.document?.name.slice(0, 18) + '... - ' : ''}${
                                    values.document ? values?.document?.size : ''
                                }`}
                                onFileChange={setFieldValue}
                                setError={setFieldError}
                            />
                        </Grid>
                    </Grid>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                        <Button type="button" danger onClick={() => setValues({})}>
                            Cancelar
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            Salvar certificado
                        </LoadingButton>
                    </CardActions>
                </Card>
            </form>

            <Grid container direction="row" spacing={3}>
                {!Boolean(data) && <CircularProgress />}
                {Boolean(data) && !Boolean(data?.length) && (
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography>Nada cadastrado</Typography>
                    </Grid>
                )}

                {Boolean(data?.length) &&
                    data.map(item => {
                        return (
                            <Grid item key={item.id} xs={12} sm={6} md={3} sx={{ marginTop: 3 }}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="subtitle2">{item.certificate_name}</Typography>
                                        <Typography variant="subtitle1">{item.Certification?.name}</Typography>
                                        <Box pt={2}>
                                            <Chip sx={{ fontWeight: 900 }} label={new Date(item.validity).toLocaleDateString()} icon={<Calendar />} />
                                        </Box>
                                    </CardContent>
                                    <CardActions style={{ justifyContent: 'flex-end' }}>
                                        <Button label="Visualizar" onClick={() => window.open(item.document_url, '_blank')}>
                                            Visualizar
                                        </Button>
                                        <Button color="error" label="Remover" onClick={() => handleRemove(item)}>
                                            Remover
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
            </Grid>
        </Box>
    );
};

const Tabs = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
