import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import * as Yup from 'yup';
import ActivityList from './ActivityList';
import { notify } from 'react-notify-toast';

import MUIRichTextEditor from 'mui-rte';
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { Editor } from '@tinymce/tinymce-react';
import { translate } from '../../../global/helpers';

const TRANSLATION = {
    pt: {
        english: 'Inglês',
        portuguese: 'Português',
    },
    en: {
        english: 'English',
        portuguese: 'Portuguese',
    },
};
export default ({ id, setActiveTab, tab }) => {
    const { data, error, mutate } = useFetch(`professional/${id}/activity`);
    useHandleErrors(error);

    const editorRef = useRef();
    const [InternalTab, setInternalTab] = useState(0);
    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [contentEditor, setContentEditor] = useState();

    const ActivityScheema = Yup.object().shape({
        language: Yup.string().required('Campo obrigatório'),
        contractor: Yup.string().required('Campo obrigatório'),
        initTime: Yup.date().required('Campo obrigatório'),
        endTime: Yup.date().nullable(),
        role: Yup.string().required('Campo obrigatório'),
        responsability: Yup.string().required('Campo obrigatório'),
        currentActivity: Yup.boolean().nullable(),
    });

    const { values, errors, touched, isSubmitting, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit, setValues } = useFormik({
        initialValues: {
            language: 'portuguese',
            contractor: '',
            initTime: '',
            endTime: '',
            role: '',
            responsability: '',
            currentActivity: false,
        },
        validationSchema: ActivityScheema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                values.responsability = editorRef.current.getContent();
                if (values.id) {
                    await server.put(`professional/${id}/activity/${values.id}`, values);
                } else {
                    await server.post(`professional/${id}/activity`, values);
                }

                handleReset();
                mutate();
                if (values.language === 'portuguese') setInternalTab(0);
                if (values.language === 'english') setInternalTab(1);
                notify.show('Dados inseridos');
                setSubmitting(false);
                setActiveTab(tab + 1);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const remove = async item => {
        try {
            await server.delete(`professional/${id}/activity/${item.id}`);
            await mutate();
            handleReset();
            notify.show('Item removido', { variant: 'success' });
        } catch (error) {
            console.log(error);
            setErrors(error);
        }
    };

    const handleRemove = item => {
        confirm({ title: 'Excluir', description: 'Confirma a exlusão', renderToPortal: true })
            .then(res => {
                remove(item);
            })
            .catch(() => {
                //nothing
            });
    };

    const toDraft = content => {
        const { contentBlocks, entityMap } = convertFromHTML(content);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = convertToRaw(contentState);
        return JSON.stringify(editorState);
    };

    const toHtml = content => {
        const markup = draftToHtml(convertToRaw(content.getCurrentContent()));
        return markup;
    };

    const toEdit = item => {
        setValues(item);
        setContentEditor(toDraft(item.description));
    };

    return (
        <Box sx={{ marginX: 3, marginY: 4 }}>
            <Grid sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">Fale sobre as atividades que você já desenvolveu</Typography>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Tabs
                    value={InternalTab}
                    onChange={(evt, activedTab) => {
                        setInternalTab(activedTab);
                        if (activedTab === 0) setFieldValue('language', 'portuguese');
                        if (activedTab === 1) setFieldValue('language', 'english');
                    }}
                >
                    <Tab label="Português" />
                    <Tab label="Inglês" />
                </Tabs>
            </Grid>

            <form onSubmit={handleSubmit}>
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                    <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                        {/* <Grid item xs={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="edducation">Linguagem</InputLabel>
                                    <Select
                                        labelId="edducation"
                                        id="language"
                                        name="language"
                                        value={values.language}
                                        helperText={errors.language}
                                        label="Linguagem"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="english">Inglês</MenuItem>
                                        <MenuItem value="portuguese">Português</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label={translate[values.language].contractor}
                                type="text"
                                name="contractor"
                                value={values.contractor}
                                helperText={errors.contractor}
                                error={errors.contractor && touched.contractor}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid item xs={12} md={3}>
                                <DesktopDatePicker
                                    label={translate[values.language].init}
                                    inputFormat="dd/MM/yyyy"
                                    value={values.initTime}
                                    onChange={e => setFieldValue('initTime', e)}
                                    onBlur={handleBlur}
                                    name="contractor"
                                    renderInput={params => (
                                        <TextField {...params} error={errors.initTime && touched.initTime} helperText={errors.initTime} fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DesktopDatePicker
                                    label={translate[values.language].end}
                                    disabled={values.currentActivity}
                                    inputFormat="dd/MM/yyyy"
                                    name="endTime"
                                    value={values.endTime}
                                    onChange={e => setFieldValue('endTime', e)}
                                    onBlur={handleBlur}
                                    renderInput={params => (
                                        <TextField {...params} error={errors.endTime && touched.endTime} helperText={errors.endTime} fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} justifyContent="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={values.currentActivity} name="currentActivity" type="checkbox" onChange={handleChange} />
                                    }
                                    label={translate[values.language].until_today}
                                />
                            </Grid>
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label={translate[values.language].role}
                                type="text"
                                name="role"
                                value={values.role}
                                helperText={errors.role}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.role && touched.role}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <MUIRichTextEditor
                                defaultValue={contentEditor}
                                //controls={['undo', 'redo', 'bold', 'italic', 'underline', 'bulletList', 'numberList', 'block']}
                                onChange={e => setFieldValue('responsability', toHtml(e))}
                                label="Descreva suas responsabilidades "
                            /> */}
                            <Editor
                                apiKey={process.env.REACT_APP_TINY}
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                init={{
                                    height: 300,
                                }}
                                initialValue={values.responsability}
                                onEditorChange={e=>setFieldValue('responsability',e)}
                                textareaName="responsability"
                            />
                            <FormHelperText>{errors.responsability}</FormHelperText>
                            {/* <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                required
                                label="Responsabilidade"
                                placeholder="Descreva sobre a atividade realizada"
                                type="text"
                                name="responsability"
                                value={values.responsability}
                                helperText={errors.responsability}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.responsability && touched.responsability}
                            /> */}
                        </Grid>
                    </Grid>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                        <Button type="button" color="error" onClick={() =>{ handleReset(); setInternalTab(0)}}>
                            Cancelar
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            {values.id ? 'Salvar alteração' : 'Adicionar atividade'}
                        </LoadingButton>
                    </CardActions>
                </Card>
            </form>

            <TabsContainer>
                <TabBody container spacing={3} display={values.language === 'portuguese' ? 'block' : 'none'}>
                    <ActivityList data={data} setValues={setValues} handleRemove={handleRemove} language="portuguese" />
                </TabBody>
                <TabBody container spacing={3} display={values.language === 'english' ? 'block' : 'none'}>
                    <ActivityList data={data} setValues={setValues} handleRemove={handleRemove} language="english" />
                </TabBody>
            </TabsContainer>
        </Box>
    );
};

const TabsContainer = styled.div`
    width: 100%;
`;

const TabBody = styled(Grid)`
    padding-top: 10px;
    padding-bottom: 10px;
`;
