import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import Box from '@mui/material/Box';

import { useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import server from '../../../global/server';

import useHandleErrors from '../../../hooks/useHandleErrors';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

export default ({ id }) => {
    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const RegisterSchema = Yup.object().shape({
        password: Yup.string().min(6, 'Mínimo de 6 caracteres').required('Campo obrigatório'),
        repeat_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
            .required('Campo obrigatório'),
    });
    
    const { values, errors, touched, isSubmitting, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit } = useFormik({
        initialValues: { id, password: '', repeat_password: '' },
        validationSchema: RegisterSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const response = await server.put(`/professional/${values.id}`, {password: values.password});
                enqueueSnackbar('Dados atualizados');
                handleReset();
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ marginX: 3, marginTop: 4 }}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={6}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.password}
                                helperText={errors.password}
                                value={values.password}
                                label="Digite sua senha"
                                type="password"
                                name="password"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.repeat_password}
                                helperText={errors.repeat_password}
                                value={values.repeat_password}
                                label="Repita a senha"
                                type="password"
                                name="repeat_password"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={2} sx={{ marginY: 3, paddingX: 3 }}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            Salvar senha
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const Tabs = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
