import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Filter from './components/Filter';
// import GoogleMaps from './components/GoogleMaps';
import GoogleMapsView from './components/GoogleMapsView';
import Search from './components/Search';

export default props => {
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container direction="row" sx={{height:"100%"}}>
                <Grid xs={4} item sx={{height:"100%", overflow: 'scroll', padding:3, display:{xs: mobile&& "none", md: 'block'}}}>
                    <Search />
                </Grid>
                <Grid xs item sx={{height:"100%"}}>
                    <GoogleMapsView />
                </Grid>
            </Grid>

            <Filter />
        </>
    );
};
