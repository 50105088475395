import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFetch } from '../hooks/useFetch';
import { FormControl, FormHelperText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default ({ value = [], filter, label, error, helperText, onChange, ...props }) => {
    const { data } = useFetch('/settings/education', {
        params: { order: 'ASC' },
    });

    if (filter) {
        return (
            <>
                <Autocomplete
                    {...props}
                    multiple
                    error={error}
                    getOptionLabel={render => render.name}
                    options={data || []}
                    value={value}
                    loading={!Boolean(data)}
                    onChange={(e, value, reason, details) => onChange(value)}
                    filterSelectedOptions
                    id="certification-select"
                    sx={{ width: '100%' }}
                    renderInput={params => <TextField {...params} error={Boolean(error)} helperText={helperText} label={label} />}
                />
                <FormHelperText error={error}>{error}</FormHelperText>
            </>
        );
    }

    return (
        <>
            <Autocomplete
                aria-required
                required
                multiple
                id="tags-outlined"
                loading={!Boolean(data)}
                options={data || []}
                getOptionLabel={data => data.name}
                value={data ? data.filter(item => value?.map(({ education_id }) => education_id).includes(item.id) && item) : []}
                filterSelectedOptions
                name="EducationUsers"
                onChange={(e, value, reason, details) =>
                    onChange({ name: 'EducationUsers', value: value.map(({ id, ...item }) => ({ ...item, education_id: id })) }, reason)
                }
                renderInput={params => (
                    <TextField {...params} error={Boolean(error)} helperText={helperText} label="Formação" placeholder="Formação" />
                )}
            />
            <FormHelperText error={error}>{error}</FormHelperText>
        </>
    );
};
