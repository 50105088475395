import styled from 'styled-components';
import ProfessionalForm from '../components/ProfessionalTabs';
import { useFetch } from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import useHandleErrors from '../../../hooks/useHandleErrors';
import LoadingContent from '../../../components/LoadingContent';
import ErrorContent from '../../../components/ErrorContent';
import { useAuth } from '../../Auth/context/AuthContext';

export default props => {
    const { user } = useAuth();
    const { data, error, isLoading, mutate } = useFetch(`professional/${user.id}`);
    useHandleErrors(error);

    if (isLoading) return <LoadingContent label="Carregando cadastro" />;
    if (error) return <ErrorContent label={error.message} />;

    return <ProfessionalForm id={user.id} data={data} mutate={mutate} />;
};

const Tabs = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
