import axios from 'axios';
export const SERVER = process.env.NODE_ENV === 'production'? process.env.REACT_APP_ENVIRONMENT === 'develop'? process.env.REACT_APP_DEV_SERVER: process.env.REACT_APP_PROD_SERVER : process.env.REACT_APP_LOCALHOST_SERVER

console.log(SERVER)
const server = axios.create({
    baseURL: SERVER,
    timeout: 10000,
    timeoutErrorMessage: 'Servidor não responde. Tente mais tarde.'
});

server.interceptors.response.use(
    response => response,
    error => {
        throw error;
    },
);

export default server;
