import { useCountry } from '../hooks/useLocales';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default ({ country, onChange, name, value, ...props }) => {
    const { data } = useCountry();
    return (
        <Autocomplete
            {...props}
            getOptionLabel={render => render.name}
            options={data || []}
            onChange={(e, value) => onChange(Boolean(value)?value.iso2: '')}
            value={data && data.filter(({ iso2 }) => iso2 === value)[0] || null}
            loading={!Boolean(data)}
            disablePortal
            id="country-select"
            sx={{ width: '100%' }}
            renderInput={params => <TextField {...params} label="País" />}
        />
    );
};
