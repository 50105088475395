import SettingsTabs from './SettingsTabs';
import { ContainerPageInside } from '../../../styles/ContainerPage';

export default props => {
    return (
        <ContainerPageInside>
            <SettingsTabs />
        </ContainerPageInside>
    );
};
