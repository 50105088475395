import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import styled from 'styled-components';

import { Alert } from '@mui/material';

const TRANSLATION = {
    pt: {
        english: 'Inglês',
        portuguese: 'Português',
    },
    en: {
        english: 'English',
        portuguese: 'Portuguese',
    },
};
export default ({ data, toEdit, handleRemove, language }) => {
    if (!Boolean(data))
        return (
            <Grid item xs={12}>
                <Alert severity="info">Nenhum item cadastrado</Alert>
            </Grid>
        );

    const list = data.filter(item => item.language === language);

    return (
        <>
            {list.map(item => {
                return (
                    <Grid item key={item.id} xs={12} sx={{ marginTop: 3 }}>
                        <Card>
                            {/* <Typography sx={{ padding: '0.5rem 1rem' }}>{TRANSLATION['pt'][item.language]}</Typography> */}
                            <Divider />

                            <div style={{ padding: '2rem' }} className="preview-exp">
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: item.description,
                                    }}
                                ></Typography>
                            </div>

                            <Divider />
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <Button variant="text" onClick={() => toEdit(item)} trailingIcon="arrow_forward">
                                    Editar
                                </Button>
                                <Button variant="text" color="error" onClick={() => handleRemove(item)} trailingIcon="close">
                                    Remover
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                );
            })}
        </>
    );
};

const TabsContainer = styled.div`
    width: 100%;
`;

const TabBody = styled(Grid)`
    display: ${props => (props.activeBody ? 'block' : 'none')};
    padding-vertical: 10px;
`;
