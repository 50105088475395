import styled from 'styled-components';

export default styled.div`
    min-height: 100%;

    @media (max-width: 699px) {
        width: 100%;
        margin: 20px;
    }
`;

export const ContainerPageInside = styled.div`
    @media (min-width: 700px) {
        max-width: 1000px;
        margin: 0 auto;
    }
`;
