import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';
import Container from './Container';
import ProtectedLoginDashboard from './ProtectedLoginDashboard';

export const ProtectedLayout = () => {
    const { logged, user} = useAuth();
    if (!logged) {
        return <ProtectedLoginDashboard />;
    }

    if (!user.admin) return <p> Acesso não permitido </p>;

    return (
        <Container>
            <Outlet />
        </Container>
    );
};
