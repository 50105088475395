import { useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import * as Yup from 'yup';

// const TRANSLATION = {
//     pt: {
//         english: 'Inglês',
//         portuguese: 'Português',
//     },
//     en: {
//         english: 'English',
//         portuguese: 'Portuguese',
//     },
// };

export default ({ data, setValues, handleRemove, language }) => {
    if (!Boolean(data))
        return (
            <Grid item xs={12}>
                <Alert severity="info">Nenhum item cadastrado</Alert>
            </Grid>
        );

    const list = data.filter(item => item.language === language);

    return (
        <>
            {list.map(item => (
                <Grid item key={item.id} xs={12}>
                    <Card>
                        {/* <Typography sx={{ padding: '0.5rem 1rem' }}>{TRANSLATION['pt'][item.language]}</Typography> */}
                        <Divider variant="inset" component="span" />

                        <div style={{ padding: '1rem' }}>
                            <Typography>
                                {new Date(item.initTime).toLocaleDateString('pt-BR')} -{' '}
                                {!item.currentActivity && new Date(item.endTime).toLocaleDateString('pt-BR')} {item.currentActivity && 'Até hoje'} -{' '}
                                {item.contractor} - {item.role}
                            </Typography>
                             
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: item.responsability,
                                }}
                                tag="p"
                            ></Typography>
                        </div>

                        <Divider variant="inset" component="span" />
                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button variant="conteint" onClick={() => setValues(item)} trailingIcon="arrow_forward">
                                Editar
                            </Button>
                            <Button variant="text" color="error" label="Remover" onClick={() => handleRemove(item)} trailingIcon="close">
                                Remover
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </>
    );
};

const TabsContainer = styled.div`
    width: 100%;
`;

const TabBody = styled(Grid)`
    display: ${props => (props.activeBody ? 'block' : 'none')};
    padding-vertical: 10px;
`;
