import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import MUIRichTextEditor from 'mui-rte';
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExperienceList from './ExperienceList';

import { Editor } from '@tinymce/tinymce-react';

const TRANSLATION = {
    pt: {
        english: 'Inglês',
        portuguese: 'Português',
    },
    en: {
        english: 'English',
        portuguese: 'Portuguese',
    },
};
export default ({ id, setActiveTab, tab }) => {
    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { data, error, mutate } = useFetch(`professional/${id}/experience`);
    useHandleErrors(error);

    const editorRef = useRef();

    const [InternalTab, setInternalTab] = useState(0);
    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const [contentEditor, setContentEditor] = useState();

    const { values, errors, touched, isSubmitting, setFieldValue, setValues, handleReset, handleChange, handleSubmit } = useFormik({
        initialValues: {
            language: 'portuguese',
            description: '',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.description = editorRef.current.getContent();
                if (values.id) {
                    await server.put(`professional/${id}/experience/${values.id}`, values);
                } else {
                    await server.post(`professional/${id}/experience`, values);
                }
                resetForm();
                setContentEditor();

                enqueueSnackbar('Dados inseridos');
                setSubmitting(false);
                setActiveTab(tab + 1);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const remove = async item => {
        try {
            await server.delete(`professional/${id}/experience/${item.id}`);
            enqueueSnackbar('Item removido');
            mutate();
        } catch (error) {
            setErrors(error);
        }
    };

    const handleRemove = item => {
        confirm({ title: 'Excluir', description: 'Confirma a exlusão', renderToPortal: true })
            .then(res => {
                remove(item);
            })
            .catch(() => {});
    };

    const toDraft = content => {
        const { contentBlocks, entityMap } = convertFromHTML(content);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = convertToRaw(contentState);
        return JSON.stringify(editorState);
    };

    const toHtml = content => {
        const markup = draftToHtml(convertToRaw(content.getCurrentContent()));
        return markup;
    };

    const toEdit = item => {
        setValues(item);
        setContentEditor(toDraft(item.description));
    };

    return (
        <Box sx={{ marginX: 3, marginY: 4 }}>
            <Grid sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        Total de anos de experiência, educação, graduação, nome da faculdade e ano de complemento. <br />
                        Obs: Não incluir formações incompletas
                    </Typography>
                </Grid>
            </Grid>
            <Tabs
                value={InternalTab}
                onChange={(evt, activedTab) => {
                    console.log(activedTab);
                    setInternalTab(activedTab);
                    if (activedTab === 0) setFieldValue('language', 'portuguese');
                    if (activedTab === 1) setFieldValue('language', 'english');
                }}
            >
                <Tab label="Português" />
                <Tab label="Inglês" />
            </Tabs>
            <form onSubmit={handleSubmit}>
                <Card sx={{ padding: 2, marginBottom: 3 }}>
                    <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12}>
                            {/* <Box sx={{ minWidth: 120 }}>
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="edducation">Linguagem</InputLabel>
                                <Select
                                    required
                                    labelId="edducation"
                                    id="language"
                                    name="language"
                                    value={values.language || ''}
                                    label="Linguagem"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="english">Inglês</MenuItem>
                                    <MenuItem value="portuguese">Português</MenuItem>
                                </Select>
                            </FormControl>
                        </Box> */}
                        </Grid>

                        <Grid item xs={12}>
                            {/* <RichEditor
                            name="description"
                            id="description"
                            invalid={errors.description}
                            onChangeText={val => setFieldValue('description', val)}
                            defaultValue={values.description}
                            onBlur={handleBlur}
                        /> */}
                            <Editor
                                apiKey={process.env.REACT_APP_TINY}
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                init={{
                                    height: 300,
                                }}
                                initialValue={values.description}
                                onChange={handleChange}
                                textareaName="description"
                            />
                            {/* <MUIRichTextEditor
                                defaultValue={contentEditor}
                                controls={['undo', 'redo', 'bold', 'italic', 'underline', 'bulletList', 'numberList']}
                                onChange={e => setFieldValue('description', toHtml(e))}
                                label="Conte os fatos sobre a sua experiência"
                            /> */}
                        </Grid>

                        <Grid item xs={12} style={{ textAlign: 'right' }} gap={3}></Grid>
                    </Grid>
                    <CardActions style={{ justifyContent: 'flex-end' }}>
                        {values.id && (
                            <Button
                                color="error"
                                onClick={() => {
                                    handleReset();
                                    setContentEditor();
                                }}
                                disabled={isSubmitting}
                            >
                                Cancelar
                            </Button>
                        )}
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            Salvar dados
                        </LoadingButton>
                    </CardActions>
                </Card>
            </form>
            <TabsContainer>
                <TabBody container spacing={3} display={values.language === 'portuguese' ? 'block' : 'none'}>
                    <ExperienceList data={data} toEdit={toEdit} handleRemove={handleRemove} language="portuguese" />
                </TabBody>
                <TabBody container spacing={3} display={values.language === 'english' ? 'block' : 'none'}>
                    <ExperienceList data={data} toEdit={toEdit} handleRemove={handleRemove} language="english" />
                </TabBody>
            </TabsContainer>
        </Box>
    );
};

const TabsContainer = styled.div`
    width: 100%;
`;

const TabBody = styled(Grid)`
    padding-top: 10px;
    padding-bottom: 10px;
`;
