import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';

import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import SelectCountry from '../../../components/SelectCountry';
import SelectState from '../../../components/SelectState';
import SelectCity from '../../../components/SelectCity';
import server from '../../../global/server';
import { useNavigate } from 'react-router-dom';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import SelectEducation from '../../../components/SelectEducation';
import SelectOccupation from '../../../components/SelectOccupation';
import SelectProfessions from '../../../components/SelectProfessions';

import SelectLanguages from '../../../components/SelectLanguages';
import { FormHelperText, IconButton, InputAdornment } from '@mui/material';
import address from '../../../utils/address';
import * as Yup from 'yup';
import ReactInputMask from 'react-input-mask';

export default ({ data, isLoading, error, tab, mutate, setActiveTab }) => {
    const navigaton = useNavigate();

    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const ValidateForm = Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        //birthday: Yup.date(), //.required('Campo obrigatório'),
        email: Yup.string().email('Email inválido'), //.required('Campo obrigatório'),
        // country: Yup.string().required('Campo Obrigatório'),
        // state: Yup.string().required('Campo obrigatório'),
        zipcode: Yup.string().required('Campo obrigatório'),
        // education: Yup.string().required('Campo obrigatório'),
        // nationality: Yup.string().required('Campo obrigatório'),
        //EducationUsers: Yup.array().min(1, 'Selecione 1 opção'), //.required('Campo obrigatório'),
        //OccupationAreaUsers: Yup.array().min(1, 'Selecione 1 opção'), //.required('Campo obrigatório'),
        //LanguagesUsers: Yup.array().min(1, 'Selecione 1 opção'), //.required('Campo obrigatório'),
    });

    const { values, errors, touched, isSubmitting, setFieldValue, handleChange, handleBlur, setValues, handleSubmit } = useFormik({
        initialValues: {
            name: '',
            birthday: '',
            email: '',
            cellphone: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            education: '',
            nationality: '',
            EducationUsers: [],
            //ProfessionsUsers: [],
            OccupationAreaUsers: [],
            LanguagesUsers: [],
        },
        validationSchema: ValidateForm,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (values.id) {
                    const response = await server.put(`/professional/${values.id}`, values);
                    await mutate();
                    enqueueSnackbar('Dados atualizados');
                } else {
                    const response = await server.post('/professional', values);
                    enqueueSnackbar('Profissional inserido na base');
                    setSubmitting(false);
                    navigaton(`/dashboard/professional/${response.data.userId}`);
                }
                setSubmitting(false);
                setActiveTab(tab + 1);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            console.log(data);
            setValues(data, false);
        }
    }, [data]);

    // const handleZipCode = async e => {
    //     try {
    //         if (Boolean(values.zipcode)) {
    //             const response = await server.get(`zipcode/${values.zipcode}`);
    //             const { address_components } = response.data.data;
    //             console.log(response.data.data);
    //             setFieldValue('country', address(address_components, 'country'));
    //             setFieldValue('city', address(address_components, 'city'));
    //             setFieldValue('state', address(address_components, 'state'));
    //             setFieldValue('address', address(address_components, 'street'));
    //         }
    //     } catch (error) {
    //         setErrors(error);
    //     }
    // };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{ marginX: 3, marginTop: 4 }}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.name}
                                helperText={errors.name}
                                value={values.name}
                                label="Nome completo"
                                type="text"
                                name="name"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.birthday}
                                helperText={errors.birthday}
                                value={values.birthday}
                                label="Data de nascimento"
                                type="date"
                                name="birthday"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.email}
                                helperText={errors.email}
                                value={values.email}
                                label="Email"
                                type="email"
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.cellphone}
                                helperText={errors.cellphone}
                                value={values.cellphone}
                                label="Telefone"
                                type="text"
                                name="cellphone"
                                maxLength={8}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ReactInputMask
                                mask="********"
                                variante="outlined"
                                disableUnderline={true}
                                maskChar=""
                                disabled={isSubmitting}
                                fullWidth
                                required
                                // onBlur={handleZipCode}
                                onChange={handleChange}
                                error={errors.zipcode}
                                helperText={errors.zipcode}
                                value={values.zipcode}
                                label="CEP"
                                type="text"
                                name="zipcode"
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton>
                                //             <SearchIcon />
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            >
                                {inputProps => <TextField {...inputProps} fullWidth disableUnderline style={{ WebkitAppearance: 'none' }} />}
                            </ReactInputMask>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {/* <TextField
                                variante="outlined"
                                //disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                //onChange={handleChange}
                                disabled
                                error={errors.country}
                                helperText={errors.country}
                                value={values.country}
                                label="País"
                                type="text"
                                name="country"
                            /> */}
                            <SelectCountry
                                required
                                name="country"
                                onChange={value => {
                                    setFieldValue('country', value);
                                    setFieldValue('state', '');
                                    setFieldValue('city', '');
                                }}
                                onBlur={handleBlur}
                                value={values.country}
                                country={values.country}
                                invalid={errors.country}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {/* <TextField
                                variante="outlined"
                                //disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                //onChange={handleChange}
                                disabled
                                error={errors.state}
                                helperText={errors.state}
                                value={values.state}
                                label="Estado"
                                type="text"
                                name="state"
                            /> */}
                            <SelectState
                                required
                                name="state"
                                country={values.country}
                                onChange={value => {
                                    setFieldValue('state', value);
                                    setFieldValue('city', '');
                                }}
                                onBlur={handleBlur}
                                value={values.state}
                                invalid={errors.state}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {/* <TextField
                                variante="outlined"
                                //disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                //onChange={handleChange}
                                disabled
                                error={errors.city}
                                helperText={errors.city}
                                value={values.city}
                                label="Cidade"
                                type="text"
                                name="city"
                            /> */}
                            <SelectCity
                                required
                                name="city"
                                country={values.country}
                                state={values.state}
                                onChange={value => setFieldValue('city', value)}
                                onBlur={handleBlur}
                                value={values.city}
                                invalid={errors.city}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                //required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.address}
                                helperText={errors.address}
                                value={values.address}
                                label="Endereço"
                                type="text"
                                name="address"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth error={Boolean(errors.education)}>
                                    <InputLabel error={Boolean(errors.education)} id="edducation">
                                        Escolaridade
                                    </InputLabel>
                                    <Select
                                        labelId="edducation"
                                        id="education"
                                        name="education"
                                        value={values.education || ''}
                                        label="Escolaridade"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="elementary">Fundamental</MenuItem>
                                        <MenuItem value="middle">Médio</MenuItem>
                                        <MenuItem value="high">Superior</MenuItem>
                                    </Select>
                                    <FormHelperText disabled={Boolean(errors.education)}>{errors.education}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variante="outlined"
                                disabled={isSubmitting}
                                fullWidth
                                // required
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={errors.nationality}
                                helperText={errors.nationality}
                                value={values.nationality}
                                label="Nacionalidade"
                                type="text"
                                name="nationality"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectEducation
                                error={errors.EducationUsers}
                                value={values.EducationUsers}
                                onChange={e => setFieldValue(e.name, e.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectOccupation
                                error={errors.OccupationAreaUsers}
                                value={values.OccupationAreaUsers}
                                onChange={e => setFieldValue(e.name, e.value)}
                            />
                        </Grid>

                        {/* <Grid item xs={6}>
                            <SelectProfessions value={values.ProfessionsUsers} onChange={e => setFieldValue(e.name, e.value)} />
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <SelectLanguages
                                error={errors.LanguagesUsers}
                                value={values.LanguagesUsers}
                                onChange={e => setFieldValue(e.name, e.value)}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={2} sx={{ marginY: 3, paddingX: 3 }}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            Salvar perfil
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const Tabs = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
