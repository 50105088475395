import { Outlet} from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';
import Container from './Container';
import ProtectedLoginProfessional from './ProtectedLoginProfessional';

export const ProtectedProfile = () => {
    const { logged, user } = useAuth();
    if (!logged) {
        return <ProtectedLoginProfessional />;
    }

    if(user.admin) return <p> Sua sessão é administrativa </p>
    
    return (
        <Container>
            <Outlet />
        </Container>
    );
};
