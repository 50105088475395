import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import styled from 'styled-components';
import { useApp } from '../../../context/AppContext';
import ModalProfessional from './ModalProfessional';

import LoadingContent from '../../../components/LoadingContent';
import { Alert, ListItemButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default props => {

    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const { resultFilter, userId, setUserId } = useApp();

    const navigation = useNavigate();

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    if (!resultFilter) return <LoadingContent label="Caregando resultado da busca" />;

    return (
        <div>
            <Typography use="headline6">Resultados encontrados</Typography>

            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {resultFilter.map(item => (
                    <>
                        <ListItemButton
                            onClick={() => {
                                //navigation(`/professional/${item.id}`)
                                setUserId(item.id);
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.User.name}
                                secondary={Boolean(item.distance_for)&&`${item.distance_for.toFixed(2)}Km de distância`}
                            />
                        </ListItemButton>
                        <Divider variant="inset" component="li" />
                    </>
                ))}
                {resultFilter.length === 0 && <Alert severity="error">Nenhum resultado encontrado</Alert>}
            </List>

            <Dialog fullScreen={mobile} fullWidth  maxWidth="lg" open={Boolean(userId)} scroll="paper" sx={{zIndex:1}}>
                {userId && <ModalProfessional handleClose={() => setUserId()} id={userId} />}
            </Dialog>
        </div>
    );
};

const ContainerFilter = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
`;
