import React, { forwardRef, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './modules/Auth/context/AuthContext';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { makeStyles } from '@mui/styles';
import Notifications from 'react-notify-toast';

const myTheme = createTheme({
    palette: {
        //mode: 'light',
    },
});

Object.assign(myTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
                border: '1px solid #ccc',
                borderRadius: '4px',
            },
            editor: {
                borderTop: '1px solid #ccc',
                paddingLeft: '30px',
            },
            placeHolder: {
                fontSize: '16px',
                paddingLeft: '20px',
                position: 'inherit',
            },
        },
        MuiSnackbar: {
            root: {
                zIndex: `100000 !importante`,
                backgroundColor: 'red',
            },
        },
    },
});

function App() {
    return (
        <>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                
            >
                <ThemeProvider theme={myTheme}>
                    <BrowserRouter basename="/">
                        <AuthProvider>
                            <AppProvider>
                                <ConfirmProvider>
                                    <Routes />
                                </ConfirmProvider>
                            </AppProvider>
                        </AuthProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </SnackbarProvider>
            <Notifications options={{zIndex: 10000, top: '50px'}} />
            <div role="presentation"></div>
        </>
    );
}

export default App;
