import { useFetch } from '../../../hooks/useFetch';
import ProfessionalTabs from '../../Professional/components/ProfessionalTabs';
import LoadingContent from '../../../components/LoadingContent';
import useHandleErrors from '../../../hooks/useHandleErrors';
import ErrorContent from '../../../components/ErrorContent';
import server, { SERVER } from '../../../global/server';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import TrashIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import { Button, DialogContent, DialogTitle, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { notify } from 'react-notify-toast';

export default ({ id, handleClose }) => {
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [isDownloading, setDownloading] = useState();
    const { data, error, isLoading, mutate } = useFetch(`professional/${id}`);
    useHandleErrors(error);

    const [err, setErr] = useState();
    useHandleErrors(err);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (isLoading) return <LoadingContent label="Carregando cadastro" />;

    if (error)
        return (
            <ErrorContent label={error.message}>
                <Button onClick={handleClose}>Fechar</Button>
            </ErrorContent>
        );

    const downloadPDF = async ({ name, id }, language) => {
        try {
            setDownloading(true);
            const { data } = await server.get(`/curriculo/${id}`, {
                params: {
                    language 
                },
                headers: {
                    'Content-Type': 'application/pdf',
                },
                responseType: 'blob',
                timeout: 5000,
            });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([data]));
            link.href = url;
            link.setAttribute('download', `${name} - ${language.toUpperCase()} ${new Date().toLocaleDateString()}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloading();
        } catch (error) {
            setDownloading();
            notify.show('Erro. Revise os dados do cadastro', { variant: 'portal' });
        }
    };

    const blockProfessional = async () => {
        try {
            await server.put(`/professional/${id}`, { status: !data.status });
            await mutate();
            notify.show('Cadastrado alterado');
        } catch (error) {
            setErr(error);
        }
    };

    const deleteProfessional = async () => {
        try {
            await server.delete(`/professional/${id}`);
            handleClose();
            notify.show('Cadastrado removido');
        } catch (error) {
            setErr(error);
        }
    };

    return (
        <>
            <DialogTitle sx={{ margin: 0, padding: 0 }}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Visualização
                        </Typography>

                        <div>
                            <LoadingButton
                                loading={isDownloading}
                                variant="text"
                                //onClick={() => downloadPDF(data)}
                                color="inherit"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                endIcon={<DownloadIcon />}
                            >
                                {mobile ? '' : 'Baixar PDF'}
                            </LoadingButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={e => downloadPDF(data, 'english')}>English</MenuItem>
                                <MenuItem onClick={e => downloadPDF(data, 'portuguese')}>Português</MenuItem>
                            </Menu>
                        </div>
                        <Button variant="text" color="inherit" onClick={() => blockProfessional()} endIcon={<BlockIcon />}>
                            {data.status ? 'Bloquear' : 'Desbloquear'} profissional
                        </Button>

                        <Button
                            variant="text"
                            color="inherit"
                            onClick={() => window.confirm('Excluir profissional?', 'Ação permanente.') && deleteProfessional()}
                            endIcon={<TrashIcon />}
                        >
                            Excluir profissional
                        </Button>

                        <IconButton autoFocus edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent sx={{ margin: 0, padding: 0 }}>
                <ProfessionalTabs id={id} data={data} mutate={mutate} />
            </DialogContent>
        </>
    );
};
