import { useState } from 'react';
import styled from 'styled-components';
import CertificationsForm from '../components/CertificationsForm';
import EducationForm from '../components/EducationForm';
import OccupationForm from '../components/OccupationForm';
import ProfessionsForm from '../components/ProfessionsForm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LanguageForm from '../components/LanguageForm';

export default ({ id, data }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <Tabs value={activeTab} onChange={ (evt, activedTab)=> setActiveTab(activedTab)}>
                <Tab label="Profissões" />
                <Tab label="Certificações"/>
                <Tab label="Disciplinas"/>
                <Tab label="Formação" />
                <Tab label="Linguages" />
            </Tabs>
            <TabsContainer>
                <TabBody activeBody={activeTab === 0}>
                    <ProfessionsForm />
                </TabBody>
                <TabBody activeBody={activeTab === 1}>
                    <CertificationsForm />
                </TabBody>
                <TabBody activeBody={activeTab === 2}>
                    <OccupationForm />
                </TabBody>
                <TabBody activeBody={activeTab === 3}>
                    <EducationForm />
                </TabBody>
                <TabBody activeBody={activeTab === 4}>
                    <LanguageForm />
                </TabBody>
                 
            </TabsContainer>
        </>
    );
};

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
