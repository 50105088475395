import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFetch } from '../hooks/useFetch';
import { FormHelperText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default ({ value, filter, label, error, helperText, onChange, ...props }) => {
    const { data } = useFetch('/settings/occupation', {
        params: { order: 'ASC' },
    });

    if (filter) {
        return (
            <Autocomplete
                {...props}
                multiple
                getOptionLabel={render => render.name}
                options={data || []}
                value={value}
                loading={!Boolean(data)}
                onChange={(e, value, reason, details) => onChange(value)}
                filterSelectedOptions
                id="certification-select"
                sx={{ width: '100%' }}
                renderInput={params => <TextField {...params} error={Boolean(error)} helperText={helperText} label={label} />}
            />
        );
    }

    return (
        <>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={data || []}
                getOptionLabel={data => data.name}
                value={data ? data.filter(item => value.map(({ occupation_area_id }) => occupation_area_id).includes(item.id) && item) : []}
                loading={!Boolean(data)}
                filterSelectedOptions
                name="OccupationAreaUsers"
                onChange={(e, value, reason, details) =>
                    onChange({ name: 'OccupationAreaUsers', value: value.map(({ id, ...item }) => ({ ...item, occupation_area_id: id })) }, reason)
                }
                renderInput={params => <TextField {...params} error={Boolean(error)} label="Disciplinas" placeholder="Disciplinas" />}
            />
            <FormHelperText error={error}>{error}</FormHelperText>
        </>
    );
};
