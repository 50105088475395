import useSWR from 'swr';
import _ from 'lodash';
import axios from 'axios';

const API_KEY = 'dVpmV2Z5Q01Sb0NqOXJ1N2d6WDBjWHpkcHB4b3RRaWZ0b2UweUF3Tg==';
export function useCountry() {
    const { data, error, mutate } = useSWR(
        {
            url: 'https://api.countrystatecity.in/v1/countries',
        },
        async ({ url }) => {
            const { data } = await axios.get(url, {
                headers: {
                    redirect: 'follow',
                    'X-CSCAPI-KEY': API_KEY,
                },
            });
            return data;
        },
    );

    return { data, error, mutate };
}

export function useStates({ country }) {
    const { data, error, mutate } = useSWR(
        {
            url: `https://api.countrystatecity.in/v1/countries/${country}/states`,
            params: { country },
        },
        async ({ url, params }) => {
            if (!params.country) return [];
            
            const { data } = await axios.get(url, {
                headers: {
                    redirect: 'follow',
                    'X-CSCAPI-KEY': API_KEY,
                },
            });
             
            return data;
        },
    );

    return { data, error, mutate };
}

export function useCity({ country, state }) {
    const { data, error, mutate } = useSWR(
        {
            url: `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
            params: { country, state },
        },
        async ({ url, params }) => {
            if (!params.state || !params.country) return [];
            const { data } = await axios.get(url, {
                headers: {
                    redirect: 'follow',
                    'X-CSCAPI-KEY': API_KEY,
                },
            });
            return data;
        },
    );

    return { data, error, mutate };
}
