import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/AddCircle';
import ExitIcon from '@mui/icons-material/ExitToApp';
import ConfigIcon from '@mui/icons-material/Settings';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import FilterIcon from '@mui/icons-material/Tune';

import { useApp } from '../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';
import { Avatar, CircularProgress, Menu, MenuItem, useMediaQuery } from '@mui/material';
import server from '../global/server';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/system';
import { notify } from 'react-notify-toast';

const AppBarAdmin = ({ setOpenMenu, filter, setOpenFilter }) => {
    const { LogOut, user } = useAuth();
    const { pathname } = useLocation();

    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isDownloading, setDownloading] = useState();
    const downloadExcel = async () => {
        try {
            setDownloading(true);
            const { data } = await server.get(`/excel`, {
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                responseType: 'blob',
                params: filter,
            });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([data]));
            link.href = url;
            link.setAttribute('download', `Report.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloading();
        } catch (error) {
            setDownloading();
            notify.show('Erro. Revise os dados do cadastro', { variant: 'portal' });
        }
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton onClick={() => setOpenMenu(true)} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Mount
                </Typography>

                {pathname === '/dashboard' && (
                    <>
                        {Boolean(Object.keys(filter).length) && (
                            <>
                                <LoadingButton
                                    loading={isDownloading}
                                    variant="text"
                                    color="inherit"
                                    onClick={() => downloadExcel()}
                                    endIcon={<DownloadIcon />}
                                >
                                    {mobile ? '' : 'Baixar resultado'}
                                </LoadingButton>
                            </>
                        )}

                        {mobile ? (
                            <IconButton color="inherit" onClick={() => setOpenFilter(true)}>
                                <FilterIcon />
                            </IconButton>
                        ) : (
                            <Button variant="text" color="inherit" onClick={() => setOpenFilter(true)} endIcon={<FilterIcon />}>
                                {Boolean(Object.keys(filter).length) ? 'Mudar filtro' : 'Adicionar filtro'}
                            </Button>
                        )}
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.slice(0, 2).toUpperCase()}`,
    };
}

const AppBarProfessional = ({ setOpenMenu }) => {
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { LogOut, user } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isDownloading, setDownloading] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const downloadPDF = async language => {
        try {
            setDownloading(true);
        
            const { data } = await server.get(`/curriculo/${user.id}`, {
                params: {
                    language,
                },
                headers: {
                    'Content-Type': 'application/pdf',
                },
                responseType: 'blob',
            });
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([data]));
            link.href = url;
            link.setAttribute('download', `${user.name} - ${language.toUpperCase()} ${new Date().toLocaleDateString()}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloading();
        } catch (error) {
            setDownloading();
            notify.show('Erro. Revise os dados do cadastro', { variant: 'portal' });
        }
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Mount
                </Typography>
                <Stack direction="row" spacing={1}>
                    <div>
                        <LoadingButton
                            loading={isDownloading}
                            variant="text"
                            //onClick={() => downloadPDF(data)}
                            color="inherit"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            endIcon={<DownloadIcon />}
                        >
                            {mobile ? '' : 'Baixar PDF'}
                        </LoadingButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={e => downloadPDF('english')}>English</MenuItem>
                            <MenuItem onClick={e => downloadPDF('portuguese')}>Português</MenuItem>
                        </Menu>
                    </div>
                    {/* <IconButton color="inherit">
                        {!isDownloading? <CircularProgress color='inherit' size={20} />:<DownloadIcon />}
                    </IconButton> */}
                    <Button variant="text" color="inherit" onClick={() => LogOut()} endIcon={<ExitIcon />}>
                        Sair
                    </Button>
                    {Boolean(user.name) && <Avatar {...stringAvatar(user?.name)} />}
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
export default props => {
    const { openMenu, setOpenMenu, filter, setOpenFilter } = useApp();
    const { LogOut, user } = useAuth();
    const navigation = useNavigate();

    return (
        <>
            {user.admin ? <AppBarAdmin setOpenMenu={setOpenMenu} filter={filter} setOpenFilter={setOpenFilter} /> : <AppBarProfessional />}

            <Drawer anchor="left" open={openMenu} onClose={() => setOpenMenu(false)}>
                <Box sx={{ width: 280 }} role="presentation" onClick={() => setOpenMenu(false)}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/dashboard')}>
                                <ListItemIcon>
                                    <HomeIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/dashboard/professional/new')}>
                                <ListItemIcon>
                                    <AddIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Novo profissional" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/dashboard/settings')}>
                                <ListItemIcon>
                                    <ConfigIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Configurações" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => LogOut()}>
                                <ListItemIcon>
                                    <ExitIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Sair" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </>
    );
};
