import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';

export default () => {
    const { data, error, mutate } = useFetch(`/settings/language`, {
        params: {
            order: 'ASC',
        },
    });
    useHandleErrors(error);

    const confirm = useConfirm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [errs, setErrors] = useState();
    useHandleErrors(errs);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleReset, handleSubmit, setValues } = useFormik({
        initialValues: {
            name: '',
            id: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);

                if (Boolean(values.id)) {
                    await server.put(`/settings/language/${values.id}`, values);
                } else {
                    await server.post(`/settings/language`, values);
                }

                await mutate();
                handleReset();
                enqueueSnackbar('Dados inseridos');
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const remove = async item => {
        try {
            await server.delete(`/settings/language/${item.id}`);
            enqueueSnackbar('Item removido');
            mutate();
        } catch (error) {
            setErrors(error);
        }
    };

    const handleRemove = item => {
        confirm({ title: 'Excluir', description: 'Confirma a exlusão', renderToPortal: true })
            .then(res => {
                remove(item);
            })
            .catch(e => {});
    };

    return (
        <Box sx={{ marginX: 3, marginY: 4 }}>
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                    <Typography>Adicione Disciplina</Typography>
                </Grid>
            </Grid>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Grid sx={{ marginBottom: 2 }}>
                    <Grid xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Língua"
                            type="text"
                            name="name"
                            value={values.name}
                            error={errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid xs={12} sx={{ textAlign: 'right', marginY: 2 }}>
                        <Button color="error" type="button" onClick={() => handleReset()}>
                            Cancelar
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            {values.id ? 'Salvar alteração' : 'Adicionar'}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
            <Grid container spacing={2} direction="row">
                {!Boolean(data) && <CircularProgress />}
                {Boolean(data) && !Boolean(data?.length) && (
                    <Grid xs={12} style={{ textAlign: 'center' }}>
                        <Typography>Nada cadastrado</Typography>
                    </Grid>
                )}
                {Boolean(data?.length) &&
                    data.map(item => {
                        return (
                            <Grid item key={item.id} xs={12}>
                                <Card>
                                    <Typography style={{ padding: '0.5rem 1rem' }}>{item.name}</Typography>

                                    <CardActions style={{ justifyContent: 'flex-end' }}>
                                        <Button variant="text" onClick={() => setValues(item)}>
                                            Editar
                                        </Button>
                                        <Button variant="text" color="error" onClick={() => handleRemove(item)}>
                                            Remover
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
            </Grid>
        </Box>
    );
};

const Tabs = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
