 
import { ContainerPageInside } from '../../../styles/ContainerPage';
import ProfessionalEdit from './ProfessionalEdit';

export default props => {
    return (
        <ContainerPageInside>
            <ProfessionalEdit />
        </ContainerPageInside>
    );
};
