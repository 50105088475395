import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FileIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';

import { bytesToSize } from '../global/helpers';
import { Button } from '@mui/material';

const mimeTypes = ['application/pdf', 'image/jpg', 'image/png', 'image/jpeg'];

export default ({ onFileChange, setError, ...props }) => {
    const refInput = useRef();

    const handleFile = data => {
        if (!mimeTypes.includes(data.type)) {
            setError('document', 'Tente usar arquivos nos formatos PDF, JPEG ou PNG');
            return;
            //return dialogs.alert({title: 'Formato não suportado', body:'Tente usar arquivos nos formatos PDF, JPEG ou PNG'})
        }

        if (data.size > 5000000) {
            setError('document', `${bytesToSize(data.size)} ${data.size} Arquivo maior que 5Mb. Tente reduzir o arquivo.`);
            return;
            //dialogs.alert({title: 'Atenção', body: `${bytesToSize(data.size)} ${data.size} Arquivo maior que 5Mb. Tente reduzir o arquivo.`})
        }

        let reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
            //console.log(reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, '').slice(0,50))
            onFileChange('document', {
                base64: reader.result.replace(/^data:[a-z]+\/[a-z]+;base64,/, ''),
                name: data.name,
                size: bytesToSize(data.size),
            });
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    return (
        <>
            <TextField
                {...props}
                required
                disabled
                type="text"
                placeholder="PDF, JPEG ou PNG"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button onClick={() =>refInput.current.click() }>Selecione o arquivo</Button>
                            {/* <IconButton edge="end" onClick={() => refInput.current.click()}>
                                <FileIcon />
                            </IconButton> */}
                        </InputAdornment>
                    ),
                }}
            />

            <input
                ref={refInput}
                type="file"
                id="file"
                multiple={false}
                accept="application/pdf,image/jpeg,image/png,image/jpg"
                name="document_file"
                onChange={e => handleFile(e.target.files[0])}
                // onBlur={props.handleBlur}
                style={{ display: 'none' }}
            />
        </>
    );
};
