import { useState } from 'react';
import styled from 'styled-components';
import ProfileForm from '../../Professional/components/ProfileForm';
import ExperienceForm from '../../Professional/components/ExperienceForm';
import ActivityForm from '../../Professional/components/ActivityForm';
import CertificateForm from '../../Professional/components/CertificateForm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PassForm from '../../Professional/components/PassForm';

export default ({ id, data, mutate }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={activeTab}  onChange={ (evt, activedTab)=> setActiveTab(activedTab)}>
                <Tab label="Dados do profissional"/>
                <Tab disabled={!Boolean(id)} label="Resumo profissional" />
                <Tab disabled={!Boolean(id)} label="Experiência profissional" />
                <Tab disabled={!Boolean(id)} label="Certificação" />
                {/* <Tab disabled={!Boolean(id)} label="Acesso do usuário" /> */}
            </Tabs>
            <TabsContainer>
                <TabBody activeBody={activeTab === 0}>
                    <ProfileForm data={data} mutate={mutate} />
                </TabBody>
                <TabBody activeBody={activeTab === 1}>{Boolean(id) && <ExperienceForm id={id} />}</TabBody>
                <TabBody activeBody={activeTab === 2}>{Boolean(id) && <ActivityForm id={id} />}</TabBody>
                <TabBody activeBody={activeTab === 3}>{Boolean(id) && <CertificateForm id={id} />}</TabBody>
                {/* <TabBody activeBody={activeTab === 4}><PassForm id={id} /></TabBody> */}
            </TabsContainer>
        </>
    );
};

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
