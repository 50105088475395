import { useState } from 'react';
import styled from 'styled-components';
import ProfileForm from './ProfileForm';
import ExperienceForm from './ExperienceForm';
import ActivityForm from './ActivityForm';
import CertificateForm from './CertificateForm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default ({ id, data, mutate }) => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <Tabs variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile value={activeTab}  onChange={ (evt, activedTab)=> setActiveTab(activedTab)}>
                <Tab label="Dados do profissional"/>
                <Tab disabled={!Boolean(id)} label="Perfil profissional" />
                <Tab disabled={!Boolean(id)} label="Experiência profissional" />
                <Tab disabled={!Boolean(id)} label="Certificação" />
                {/* <Tab disabled={!Boolean(id)} label="Acesso do usuário" /> */}
            </Tabs>
            <TabsContainer>
                <TabBody activeBody={activeTab === 0}>
                    <ProfileForm data={data} tab={0} mutate={mutate} setActiveTab={setActiveTab}  />
                </TabBody>
                <TabBody activeBody={activeTab === 1}>{Boolean(id) && <ExperienceForm id={id} tab={1} setActiveTab={setActiveTab} mutate={mutate}/>}</TabBody>
                <TabBody activeBody={activeTab === 2}>{Boolean(id) && <ActivityForm id={id} tab={2} setActiveTab={setActiveTab} mutate={mutate}/>}</TabBody>
                <TabBody activeBody={activeTab === 3}>{Boolean(id) && <CertificateForm id={id} tab={3} setActiveTab={setActiveTab} mutate={mutate}/>}</TabBody>
            </TabsContainer>
        </>
    );
};

const TabsContainer = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
