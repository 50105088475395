import styled from 'styled-components';
import ProfessionalTabs from '../components/ProfessionalTabs';
import { useFetch } from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import useHandleErrors from '../../../hooks/useHandleErrors';
import LoadingContent from '../../../components/LoadingContent';
import ErrorContent from '../../../components/ErrorContent';

export default props => {
    const { professionalId } = useParams();

    const { data, error, isLoading, mutate } = useFetch(`professional/${professionalId}`);
    if (isLoading) return <LoadingContent label="Carregando cadastro" />;
    if (error) return <ErrorContent label={error.response.data.message} />;
    
    return (
        <ProfessionalTabs id={professionalId} data={data} mutate={mutate} />
    );
};

const Tabs = styled.div`
    width: 100%;
    border: 1px solid #eee;
`;

const TabBody = styled.div`
    display: ${props => (props.activeBody ? 'block' : 'none')};
`;
