import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { ProtectedLayout } from '../components/ProtectedLayout';
import { ProtectedProfile } from '../components/ProtectedProfile';
import { useAuth } from '../modules/Auth/context/AuthContext';
import Login from '../modules/Auth/screens/Login/LoginAdmin';
import LoginProfessional from '../modules/Auth/screens/Login/LoginProfessional';
import LostPass from '../modules/Auth/screens/Login/LostPass';
import ProfessionalRegister from '../modules/Auth/screens/Login/ProfessionalRegister';

import Dashboard from '../modules/Dashboard';
import Professional from '../modules/Professional/screens';
import ProfessionalEdit from '../modules/Professional/screens/ProfessionalEdit';
import ProfessionalNew from '../modules/Professional/screens/ProfessionalNew';
import Settings from '../modules/Settings/screens';
import ViewProfile from '../modules/Profile/screens/ViewProfile';

export default () => {
    return (
        <Routes>
            <Route index element={<Login />} />
            <Route path="register" element={<ProfessionalRegister />} />
            <Route path="recovery" element={<LostPass />} />

            <Route path="dashboard" element={<ProtectedLayout />}>
                <Route index element={<Dashboard />} />

                <Route path="professional" element={<Professional />}>
                    <Route index path="new" element={<ProfessionalNew />} />
                    <Route path=":professionalId" element={<ProfessionalEdit />} />
                </Route>

                <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="profile" element={<Outlet />}>
                <Route index element={<LoginProfessional />} />
                <Route element={<ProtectedProfile />}>
                    <Route index path="view" element={<ViewProfile />} />
                </Route>
            </Route>
        </Routes>
    );
};
