import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';
import Button from '@mui/material/Button';
export default ({ label, children }) => {
    const navigation = useNavigate();
    return (
        <ContainerLoading>
            <div>
                <BlockIcon />
            </div>
            <div>{label && <LoadingText variant="subtitle2">{label}</LoadingText>}</div>
            {children}
        </ContainerLoading>
    );
};

const ContainerLoading = styled.div`
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const LoadingText = styled(Typography)`
    font-weight: bold;
    font-size: 15px;
    margin-top: 10px;
    display: block;
`;
