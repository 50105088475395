import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFetch';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default ({ value, onChange, filter, label, error, helperText, ...props}) => {
    const { data } = useFetch('/settings/certifications', {
        params: { order: 'ASC' },
    });


    if(filter){
        return <Autocomplete
        {...props}
        multiple
        getOptionLabel={render => render.name}
        options={data || []}
        value={value}
        loading={!Boolean(data)}
        onChange={(e,value, reason, details)=>onChange(value)}
        filterSelectedOptions
        id="certification-select"
        sx={{ width: '100%' }}
        renderInput={params => <TextField {...params} error={Boolean(error)} helperText={helperText} label={label} />}
    />
    }
    return (
        <Autocomplete
            {...props}
            getOptionLabel={render => render.name}
            options={data ? data : []}
            onChange={(e, value) => onChange(value.id)}
            value={data && (data.filter(({ id }) => id === value)[0] || null)}
            loading={!Boolean(data)}
            disablePortal
            id="certification-select"
            sx={{ width: '100%' }}
            renderInput={params => <TextField {...params} label="Certificação" />}
        />
        
    );
};
